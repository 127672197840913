import React from 'react'

import './ContentSection.scss'

const ContentSection = props => (
  <section className="content-section" id="content-section">
    <div className="container">
      <h1>{props.heading} </h1>
      <p>{props.paragraph}</p>
      <p>{props.paragraph2}</p>
    </div>
  </section>
)

export default ContentSection
