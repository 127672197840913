import React from 'react'
import './FeatureBoxes.scss'
import '../../utils/Variables.scss'

const FeatureBoxes = props => (
  <section className="feature-section" id="feature-section">
    <div className="container">
      <div className="wrapper">
        <div className="row three">
          <div className="left blackbg">
            <h1 v-html="rowThree.left.heading">
              ARTS. CULTURE. ENTERTAINMENT.
            </h1>
            {/* <h2 /> */}
          </div>

          <div className="right" />
        </div>

        <div className="row three">
          <div className="left" />

          <div className="right greenbg">
            <h1 v-html="rowThree.right.heading">FOOD. BITES. REFRESHMENT.</h1>
            {/* <h2 /> */}
          </div>
        </div>

        <div className="row four">
          <div className="left redbg">
            <h1 v-html="rowFour.right.heading">GOODS. ART. GIFTS.</h1>
            {/* <h2 /> */}
          </div>

          <div className="right" />
        </div>
      </div>
    </div>
  </section>
)

export default FeatureBoxes
