import React from 'react'
import Layout from '../utils/Layout/Layout'
import Header from '../components/Header/Header'
// import VideoSection from '../components/VideoSection/VideoSection'
import ContentSection from '../components/ContentSection/ContentSection'
import FeatureBoxes from '../components/FeatureBoxes/FeatureBoxes'
import EmailSuscribe from '../components/EmailSuscribe/EmailSuscribe'
import ParticipateSection from '../components/ParticipateSection/ParticipateSection'
import SEO from '../components/seo'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Header heading="Celebrate culture, cuisine, & community!" paragraph="" />
    <ContentSection
      heading="Celebrate culture, cuisine, & community!"
      paragraph="AfroFest is an annual outdoor community event that celebrates St. Paul’s diverse African cultures through live musical performances, drum & dance, arts, museum quality artifacts, colorful hand woven fabrics & textiles, storytelling, and food. This cultural and family oriented event is a great opportunity for people of all cultures and backgrounds to come together to experience and celebrate great food, music, and wares from the African diaspora."
      paragraph2="AfroFest will showcase a wide array of artists, entertainers, and community leaders, and will feature a local marketplace with multiple entrepreneurs and nonprofit organizations."
    />
    <FeatureBoxes />
    {/* <VideoSection
      title="The Lion King"
      src="https://www.youtube.com/embed/O9EvBdzHvqI"
    /> */}
    <EmailSuscribe />
    <ParticipateSection />
  </Layout>
)

export default IndexPage
